var privacy = (function () {

    var cookieName = "privacy-statement";

    // Create cookie
    function createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else {
            expires = "";
        }
        document.cookie = name+"="+value+expires+"; path=/";
    }

    // Read cookie
    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1,c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length,c.length);
            }
        }
        return null;
    }

    // Erase cookie
    function eraseCookie(name) {
        createCookie(name,"",-1);
    }

    function checkPrivacyStatement() {
        if(!readCookie(cookieName)) {
            showPrivacyStatement();
        }
    }

    function createPrivacyStatement() {
        var myDiv = document.createElement("div"),
            colOne = document.createElement("div"),
            colTwo = document.createElement("div"),
            myP = document.createElement("p"),
            myButton = document.createElement("a"),
            myCookieIcon = document.createElement('span');

        colOne.classList = "col-12 col-md-9";
        colTwo.classList = "col-12 col-md-3";


        myP.innerHTML = '<b>Verwendung von Cookies:</b> Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer <a style="color:#3F98D3!important" href="/datenschutz.php">Datenschutzerklärung</a>. Um Ihre Privatsphäre zu schützen, binden wir Google Analytics und andere Tracking-Pixel erst ein, wenn Sie es ausdrücklich erlauben!';
        myP.style.margin = "0";
        myP.style.fontSize = "14px";
        myP.style.marginLeft = "50px";

        //create Close-Button
        myButton.id = "close-statement";
        myButton.className = "button";
        myButton.text = 'Akzeptieren';
        myButton.style.display = "inline-block";
        myButton.style.fontSize = "14px";
        myButton.style.position = "absolute";
        myButton.style.top = "50%";
        myButton.style.transform = "translateY(-50%)";
        myButton.style.right = "25px";
        myButton.style.zIndex = "999999999";
        myButton.style.cursor = "pointer";
        myButton.style.fontFamily = "sans-serif";
        myButton.style.fontWeight = "200";
        myButton.style.lineHeight = "1";
        myButton.style.padding = "15px 60px";
        myButton.style.letterSpacing = ".6px";
        myButton.onclick = function() {
            createCookie( cookieName, "true", 30 );
            document.getElementById( "privacy-statement" ).style.display = "none";
            document.getElementById( "privacy-statement" ).classList = 'accepted';
            window.location.reload();
        };

        myCookieIcon.className = "icon-icon-cookie";
        myCookieIcon.style.position = "absolute";
        myCookieIcon.style.top = "50%";
        myCookieIcon.style.transform = "translateY(-50%)";
        myCookieIcon.style.left = "25px";
        myCookieIcon.style.zIndex = "999999999";
        myCookieIcon.style.fontSize = "30px";
        myCookieIcon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" data-name="Ebene 1" width="32" height="32" viewBox="0 0 32 32"><title>coockies</title><rect width="32" height="32" style="fill:none"/><path style="fill:#3F98D3;" d="M676.27,381.39a1.63,1.63,0,1,1,1.63-1.62,1.63,1.63,0,0,1-1.63,1.62m3.13-1.62a3.13,3.13,0,1,0-3.13,3.12,3.13,3.13,0,0,0,3.13-3.12" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M690.12,389.23a1,1,0,1,1,1-1,1,1,0,0,1-1,1m0-3.48a2.49,2.49,0,1,0,2.49,2.49,2.5,2.5,0,0,0-2.49-2.49" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M680.26,392.33A2.35,2.35,0,1,1,682.6,390a2.35,2.35,0,0,1-2.34,2.35m0-6.19A3.85,3.85,0,1,0,684.1,390a3.84,3.84,0,0,0-3.84-3.84" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M683.35,383.15a.63.63,0,0,1,0-1.26.63.63,0,0,1,0,1.26m0-2.76a2.13,2.13,0,1,0,2.13,2.13,2.14,2.14,0,0,0-2.13-2.13" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M682.18,398.46a13.68,13.68,0,0,1-4-26.75,6.6,6.6,0,0,0,6.06,4.27,6.63,6.63,0,0,0,7,4.58c0,.08,0,.17,0,.25a4.27,4.27,0,0,0,4.55,3.31c0,.22,0,.45,0,.66a13.69,13.69,0,0,1-13.68,13.68m14.75-15.87a.74.74,0,0,0-.63-.08l-.23.06h0a2.78,2.78,0,0,1-3.32-2.09,3.29,3.29,0,0,1-.06-.77.77.77,0,0,0-.27-.63.75.75,0,0,0-.66-.14l-.12,0a5.11,5.11,0,0,1-6.12-3.83.36.36,0,0,1,0-.1.76.76,0,0,0-.79-.58,5.11,5.11,0,0,1-5.36-3.85.72.72,0,0,0-.34-.46.76.76,0,0,0-.57-.08,15.18,15.18,0,1,0,18.92,14.71,15.44,15.44,0,0,0-.09-1.64.74.74,0,0,0-.34-.55" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M687.43,370.79a1.38,1.38,0,1,0-1.37-1.37,1.37,1.37,0,0,0,1.37,1.37" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M692.53,375.93a1.72,1.72,0,1,0-1.72-1.72,1.72,1.72,0,0,0,1.72,1.72" transform="translate(-667 -368)"/><path style="fill:#3F98D3;" d="M697.62,368.69a1.38,1.38,0,1,0,1.38,1.38,1.37,1.37,0,0,0-1.38-1.38" transform="translate(-667 -368)"/></svg>';

        //add ID to MainContainer
        myDiv.id = "privacy-statement";
        myDiv.classList = "row";

        //append Elements
        myDiv.appendChild(colOne);
        myDiv.appendChild(colTwo);
        colOne.appendChild(myCookieIcon);
        colOne.appendChild(myP);
        colTwo.appendChild(myButton);

        //style MainContainer
        myDiv.style.display = "none";
        myDiv.style.position = "fixed";
        myDiv.style.bottom = "0";
        myDiv.style.right = "0";
        myDiv.style.left = "0";
        myDiv.style.zIndex = "999999999";
        myDiv.style.top = "auto";
        myDiv.style.height = "auto";
        myDiv.style.background = "#fff";
        myDiv.style.padding = "25px";
        myDiv.style.color = "#3F98D3";

        document.body.appendChild(myDiv);

        showPrivacyStatement();
    }

    function showPrivacyStatement() {
        if(document.getElementById("privacy-statement")) {
            document.getElementById("privacy-statement").style.display = "flex";
        } else {
            createPrivacyStatement();
        }
    }

    return {
        checkPrivacyStatement: checkPrivacyStatement()
    };

})();